<template>
  <div>
    <b-form-select v-model="selected" :options="seasonsList" @change="onChange"></b-form-select>
  </div>
</template>

<script>
export default {
  model: {
    prop: "selected",
    event: "change",
  },
  props: {
    selected: { type: Number, default: 0 },
    options: { type: Array, default: null },
  },
  data() {
    return {
      seasonsList: [],
    }
  },
  methods:{
    onChange(e){
      this.$emit("change",e);
    },
    refresh(){
      if(this.options == null)
        this.seasonsList = [
          { value: null, text: "Choisissez une saison"},
          { value: 2023, text: "2023"},
          { value: 2022, text: "2022"},
          { value: 2021, text: "2021"},
          { value: 2020, text: "2020"},
        ];
      else
        this.seasonsList = this.options;
    }
  },
  mounted(){
    this.refresh();
  }
}
</script>
