<template>
    <div>
        <h1>Configuration de la compétition</h1>

        <error-summary v-model="validationErrors"></error-summary>

        <collapse-panel
          title="Général"
          :visible="isGeneralPanel_visible"
        >
            <div v-if="isTestMode">
              <b>MODE SIMULATION</b>
              <info-helper>
                <b-checkbox switch v-model="isSimulation" disabled="true">Est pour simulation uniquement</b-checkbox>
                <br />
                Vous avez activé le mode test (voir configuration).<br>
                En mode test, vous ne pouvez créer que des "fausses" compétitions, pour test.
              </info-helper>
            </div>

          <div v-if="wizStep==1">
            <p class="directive" v-if="wizStep==1">{{ wizStepDirective[0] }}</p>

            <b-row>
              <b-col sm="2">
                <form-field caption="Année/Saison" :required="true">
                  <season-selector :options= "listOfSeasons" v-model="year" @change="year_changed"/>
                </form-field>
              </b-col>
              <b-col sm="5">
                <form-field caption="Type" :required="true">
                  <b-form-select
                      v-model= "competitionTypeScope"
                      :options= "listOfCompetitionTypes"
                      @change= "competitionType_Changed"
                      required
                  />
                </form-field>
              </b-col>
              <b-col sm="5">
                <form-field
                    caption="Emplacement"
                    :required="true"
                >
                  <b-form-input type="text" v-model="location" class="location" @change="location_changed" required debounce="750" />
                </form-field>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="5">
                <form-field
                  caption='Prévue du'
                  :required="true"
                >
                  <b-form-datepicker v-model="startDate" :min="new Date()" placeholder="choisissez..." class="startDate" locale='fr' required debounce="750" />
                </form-field>
              </b-col>
              <b-col sm="5">
                <form-field
                  caption="Au"
                  :required="false"
                >
                  <b-form-datepicker v-model="endDate" :min="endDateMin" class="endDate" locale="fr" placeholder="choisissez..." :disabled="startDate==null || startDate==''" required debounce="750" />
                </form-field>
              </b-col>
              <b-col sm="2">
                <form-field
                  caption="Durée"
                >
                  <b-form-input type="text" v-model="duration" class="duration" disabled />
                </form-field>
              </b-col>
            </b-row>
              <form-field
                  caption="Nom de la compétition"
                  :required="true"
              >
                <b-form-input type="text" v-model="name" required />
              </form-field>

              <form-field caption="Niveaux" :required="true">
                <b-form-checkbox-group
                    v-model="selectedLevels"
                    :options="levels"
                    required
                >
                  <!-- <b-form-checkbox v-for="level in levels" :key="level.code" :value="level">{{ level.name }}</b-form-checkbox>-->
                </b-form-checkbox-group>
              </form-field>

              <form-field caption='Est "Open"'>
                <b-form-checkbox v-model="isOpen" />
              </form-field>
          </div>
          <div v-else>
            <b>{{ name }}</b><br/>
            ({{ competitionTypeScope.name }} - {{ year }} - {{ location }})<br/>
            {{ levelsSummary }}
          </div>
        </collapse-panel>

        <div v-if="is_FR_CDF_CVA">
          <collapse-panel title="Epreuves & classements">
            <form-field caption="Mode de calcul du résultat final">
              <b-form-radio-group
                v-model="resultCalculationMode"
                stacked switch>
                <b-form-radio v-for="mode in listOfResultCalculationModes" :key="mode.value.code" :value="mode.value">{{ mode.text }}<info-helper v-show="mode.value.description != null && mode.value.description != ''">{{ mode.value.description }}</info-helper></b-form-radio>
              </b-form-radio-group>
            </form-field>
          </collapse-panel>
        </div>

        <error-summary v-model="validationErrors"></error-summary>

        <b-button
            variant="primary"
            @click="createCompetition_Click"
        >Valider la création
        </b-button>

    </div>
</template>

<script>
import { /*REF_COMPETITION_TYPE,*/ REF_RESULT_CALCULATION_MODE, SEASON, SEASON_COMPETITION_TYPE_SCOPE, SEASON_COMPETITION_TYPE_SCOPE_LEVEL, /*SEASON_COMPETITION_TYPE_CATEGORY_LEVEL,*/ REF_LEVEL, /*REF_CATEGORY,*/ DATA_SYNC_SUBSCRIPTIONS, /*RST_COMPETITION, RST_ROUND, RST_LEVEL, RST_CATEGORY*/ } from '@/LocalDB/index.js'
import { /*mapMutations,*/ mapGetters, mapActions } from "vuex";
import ModelViewsHelper from "@/services/store/models/ModelViews";
//import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import InfoHelper from '@/components/InfoHelper.vue';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import FormField from '@/components/Panels/FormField.vue';
import SeasonSelector from '@/components/SeasonSelector.vue';
import ErrorSummary from '@/components/Panels/ErrorSummary.vue';

//import DetailedStaffList from '@/components/DetailedStaffList.vue';

export default {
  components: { /*NumberOfRoundsEditor, */InfoHelper, CollapsePanel, FormField, SeasonSelector, ErrorSummary/*, DetailedStaffList, */},
    data(){
        return {
          wizStep: 1,
          nbWizSteps: '?',
          wizStepDirective: [
            'Pour commencer, sélectionnez le type de compétition',
          ],
          generalPanel_Visible: false,
            validationErrors: [],
            id: null,
            name: '',
            scope: 'nation',
            isOpen: false,
            location: '',
            competitionTypeScope: null,
            year: 0,
            isSimulation: this.isTestMode,
            nameSet: false,
            disabledHidden: false,
            nb_rounds: 1,
            nb_min_rounds: 1,
            roundAverageComputationRule: 'averageOfAllRounds',
            nbRoundToSkipOrKeep: 1,
            selectedLevels: [],
            resultCalculationMode: null,
            staffMembers: [],
            startDate: null,
            endDateEntry: null,
        }
    },
    computed:{
      isGeneralPanel_visible:{
        get(){ return this.generalPanel_Visible || (this.wizStep == 1); },
        set(value){ this.generalPanel_Visible = value; }
      },
      is_FR_CDF_CVA(){
        return this.competitionTypeScope != null && this.competitionTypeScope.regulation == 'FR_CDF_CVA';
      },
        isFederal(){
            if(this.selectedCompetitionType == null)
                return false;
            return this.selectedCompetitionType.isFederal;
        },
        isCva(){
            if(this.selectedCompetitionType == null)
                return false;
            return this.selectedCompetitionType.isCva;
        },
        isPartOfCdfCva(){
            if(this.selectedCompetitionType == null)
                return false;
            return this.selectedCompetitionType.isPartOfCdfCva;
        },
        isSelective(){
            return this.isPartOfCdfCva && (this.scope == "region");
        },
        season(){
            return SEASON.query().where("year", this.year).first();
        },
        listOfSeasons(){
          var flist = SEASON_COMPETITION_TYPE_SCOPE.all()
                        .map(ts => ts.year)
                        .filter((value, index, self) => { return self.indexOf(value) === index; });
          return flist;
        },
        listOfCompetitionTypes(){
            var flist = SEASON_COMPETITION_TYPE_SCOPE.query()
                          .where(cts => cts.year == this.year)
                          .get();
            flist.sort((a,b) => a.displayOrder - b.displayOrder);
            return flist.map(cts => { return { value: cts, text: cts.name }; });
        },

        /*selectedCompetitionType(){
           var sel = SEASON_COMPETITION_TYPE_SCOPE.query().where(cts => cts.id == this.competitionTypeId).first();
            return sel;
          //return this.competitionType;
        },*/
        nbRoundToSkipOrKeepVisible(){
            return this.roundAverageComputationRule != "averageOfAllRounds";
        },
        nbRoundToSkipOrKeepLabel(){
            if(this.roundAverageComputationRule == "averageOfNBestRounds")
                return "Nb. manches conservées";
            if(this.roundAverageComputationRule == "averageExcludingNWorseRounds")
                return "Nb. manches exclues";
            return "Nb. manches considérées";
        },
        levels(){
          console.log('levels', this.competitionTypeScope, this.year);
          if(this.competitionTypeScope != null)
            return SEASON_COMPETITION_TYPE_SCOPE_LEVEL.query()
                .where('year', this.year)
                .where('competition_type', this.competitionTypeScope.competition_type)
                .where('scope', this.competitionTypeScope.scope)
                .get()
                .map(item => item.level)
                .filter((value, index, self) => { return self.indexOf(value) === index})
                .map(code => { return REF_LEVEL.query().where('code', code).first(); })
                .map(level => { return { value: level, text: level.name }; });
          return null;
        },
        listOfResultCalculationModes(){
          if(this.competitionTypeScope != null)
            return REF_RESULT_CALCULATION_MODE.query()
              .where('regulation', this.competitionTypeScope.COMPETITION_TYPE.regulation)
              .get()
              .map(mode => { return { value: mode, text: mode.name}; });
          return null;
        },
        nbRounds: {
            get: function(){ return this.nb_Rounds; },
            set: function(value) { this.nb_Rounds = value; }
        },
        duration(){
          if(this.startDate == null) return 'N/A';
          if(this.endDateEntry == null) return 1;
          return this.endDateEntry - this.startDate;
        },
        endDate:{
          get: function(){ return (this.startDate != null) ? this.endDateEntry : null; },
          set: function(value) { this.endDateEntry = value;}
        },
        endDateMin(){
          if(this.startDate != null && this.startDate != '')
            return this.startDate;
          return new Date();
        },
    },
    methods:{
        ...mapGetters('modelViewsHelper', ['getDetailedStaffListItems'] ),
        ...mapActions('synchro', ['synchronizeMeta', 'synchronizeSeasonConfiguration', 'synchronizeSeasonRules']),
        // Interraction sur modification (sélection dans la liste) du type de compéitition
        competitionType_Changed(){
            this.setDefaultName();
            this.setDefaultLevel();
            this.staff = ModelViewsHelper.getDetailedStaffListItems(this.competitionTypeScope.competition_type, this.competitionTypeScope.scope, this.year, null);
        },

        year_changed(){
            this.setDefaultName();
            this.setDefaultLevel();
            this.staff = ModelViewsHelper.getDetailedStaffListItems(this.competitionTypeScope.competition_type, this.competitionTypeScope.scope, this.year, null);
        },

        location_changed(){
            this.setDefaultName();
        },

        // Interraction sur modification (saisie) du nom de la compétition
        name_Changed(){
            this.nameSet = (this.name!="");
            this.setDefaultName();
        },

        async createCompetition_Click(){
          await this.showSaver();
          this.validationErrors = [];
          var container = CompetitionsConfigurationHelper.buildCompetitionContainer(
                    this.competitionTypeScope.competition_type,
                    this.year,
                    this.competitionTypeScope.scope,
                    this.name,
                    this.location,
                    this.isOpen,
                    this.isSimulation,
                    this.competitionTypeScope.COMPETITION_TYPE.regulation,
                    this.resultCalculationMode?.code || '',
                    this.selectedLevels,
                    this.startDate,
                    this.endDate,
            );
            var errors = CompetitionsConfigurationHelper.missingRequirementsOnCreation(container);
            if(errors == null || errors.length == 0){
                var result = await CompetitionsConfigurationHelper.createCompetitionFromContainerAsync(container);
                this.hideSaver();
                this.toastAsyncSaved(result.success, "Compétition créée");
                await this.showLoader();
                this.setCurrentCompetition_id(result.competition.id);
                DATA_SYNC_SUBSCRIPTIONS.insert({ data: { type: 'RST_COMPETITION', id: result.competition.id}});
                await this.showLoader();
                this.toastSuccess('Compétition créée.')
                this.$router.push({name: 'CompetitionEdit'});
            } else {
              this.hideSaver();
              this.validationErrors = errors.map(e => e.text);
            }
        },

        setDefaultName(){
          if(!this.nameSet)
          {
            if(this.competitionTypeScope != null && this.competitionTypeScope.defaultCompetitionNameFormat != null){
              var name = this.competitionTypeScope.defaultCompetitionNameFormat;
              if(name.includes('%location%') && this.location != null && this.location != '')
                name = name.replace('%location%', this.location);
              if(name.includes('%year%') && this.year != null)
                name = name.replace('%year%', this.year);
              if(!name.includes('%location%') && !name.includes('%year%'))
                this.name = name;
            }
          }
        },

        setDefaultLevel(){
          var levels = this.levels;
          if(levels.length == 1)
            this.selectedLevels = levels.map(l => l.value);
        },

        init(){
            this.year = new Date().getFullYear();
            var listOfCompetitionTypes = this.listOfCompetitionTypes;
            if(listOfCompetitionTypes == null || listOfCompetitionTypes.length == 0)
            {
              this.synchronizeMeta();
              this.synchronizeSeasonConfiguration();
              this.synchronizeSeasonRules();
              this.year = new Date().getFullYear();
              listOfCompetitionTypes = this.listOfCompetitionTypes;
            }
            else
              this.competitionTypeScope = listOfCompetitionTypes[0].value;
            this.setDefaultName();
            this.setDefaultLevel();
            this.$hideLoader();
        },
        addRound(){
          this.nb_rounds++;
          this.setDefaultName();
        },
        removeRound(){
          this.nb_rounds--;
          this.setDefaultName();
        },
        removeKeepableRounds(){
          this.nbRoundToSkipOrKeep--;
        },
        addKeepableRounds(){
            this.nbRoundToSkipOrKeep++;
        },
    },
    mounted(){
        this.isSimulation = this.isTestMode;
        /*if(this.currentCompetition_id != null){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name: 'CompetitionsRunning'});
          })
          return;
        }*/
        this.checkSynchro();
        this.init();
        this.$hideLoader();
    }
}
</script>

<style scoped>
    .location.selective { font-weight:bolder; font-size:1.4rem; }
    .roundNo { font-weight: bolder; font-size: 1.2rem; }
    .level { font-weight: bolder; margin-top: 1.2rem; margin-bottom:0.5rem; }
    .category { min-width:100px; max-width:100px; border-width:1px; border-style:solid; padding:2px; margin: 5px; border-radius: 5px;}
    .category.actif{ border-color: orangered ;}
    .category.inactif{ border-color: darkgray; }
    .inactif { color: darkgray; cursor: default; }
    .categories-list{
        display:flex;
        align-content: space-around;
        justify-content:center;
        flex-wrap: wrap;
    }
    .completed{ border:green 1px solid; background-color:lightgreen;}
    .container{ display:grid;
        grid-template-columns: 40% 60%;
    }
    .col { margin-top: 7px; margin-bottom: 7px;}
    .col.label { grid-column: 1; text-align:right; padding-right: 2px; /*font-size:0.7em;*/  }
    .col.value { grid-column: 2; text-align:left; padding-left: 0px; /*font-size:1.2em;*/ }
    .colspan { grid-column: 1 / 3 !important; }
    .col > input[type='text'] { width: 100%; }
    .nbRounds { width:30%; }
    .actors-list-container{ display: flex; justify-content: center; flex-wrap: wrap; margin-top: 15px; width:100% }

   @media screen and (max-width: 767px) and (orientation: portrait) {
       .actors-list-container{display: flex; justify-content: flex-start; flex-wrap: wrap; margin-top: 15px; margin-left:10px; margin-right: 10px;font-size: 0.9rem; }
   }
    div.cdf{ margin-top: 5px;}
    fieldset.fieldset{margin-bottom: 2rem;}
</style>

<style>
</style>
